<template>
  <div>
    <!-- Note List UI Element Here -->
    <deleted-note-list />
  </div>
</template>

<script>
import DeletedNoteList from "../../components/keep/note/DeletedNoteList.vue";

export default {
  name: "DeletedNoteListView",
  components: { DeletedNoteList },
};
</script>