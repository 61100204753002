<template>
  <v-container fluid>
    <note-modal
      :open-modal="openModal"
      :note="selectedNote"
      @modalClosed="(openModal = false), (selectedNote = {})"
    />
    <note-update
      :note="selectedNoteForUpdate"
      :open-modal="openEditModal"
      @updateModalCollapse="
        (openEditModal = false), (selectedNoteForUpdate = {})
      "
    />
    <v-row>
      <div
        class="not-found"
        v-if="deletedNoteList.length === 0 && !loading"
      >
        No notes found.
      </div>
      <v-col
        v-for="note in deletedNoteList"
        :key="note.id"
        :cols="true"
      >
        <each-note-card
          :note="note"
          @onToggleNoteExpansion="toggleNoteExpansion"
          @openEditModal="onOpenEditModal"
        />
      </v-col>
    </v-row>
    <div class="text-center">
      <v-overlay :value="loading">
        <v-progress-circular
          v-if="loading"
          width="7"
          size="70"
          indeterminate
        />
      </v-overlay>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import EachNoteCard from "./card/EachNoteCard.vue";
import NoteModal from "./NoteModal.vue";
import NoteUpdate from "./NoteUpdate.vue";

export default {
  name: "DeletedNoteList",
  components: { EachNoteCard, NoteModal, NoteUpdate },
  computed: {
    ...mapGetters(["deletedNoteList"]),
  },
  data() {
    return {
      selectedNote: {},
      selectedNoteForUpdate: {},
      openModal: false,
      openEditModal: false,
      loading: false,
    };
  },
  methods: {
    onOpenEditModal(note) {
      this.openEditModal = true;
      this.selectedNoteForUpdate = note;
    },
    async getNoteOnQueryUpdate (noteId) {
      const _note = this.deletedNoteList.find((item) => {
        return item.id === noteId
      })
      if (!_note) {
        const response = await this.$store.dispatch("getSingleNoteFromAPI", noteId)
        if (response && response.status === 200) {
          this.selectedNote = response.data
        } else {
          this.$ebus.$emit("newToastMessage", {
            message: response.msg,
            color: "error"
          })
        }
      } else {
        this.selectedNote = _note
      }
      this.openModal = true
    },
    toggleNoteExpansion (note) {
      this.$router.push({path: this.$route.path, query: {noteId: note.id} })
      this.selectedNote = note
      this.openModal = true
    },
  },
  async created () {
    this.loading = true
    await this.$store.dispatch('getStatusWiseNotesFromAPI', 'deleted')
    this.loading = false
  },
  mounted () {
    if (this.$route.query.noteId) {
      this.getNoteOnQueryUpdate(this.$route.query.noteId)
    }
  },
  watch: {
    '$route.query.noteId' (newValue) {
      if (newValue) {
        this.getNoteOnQueryUpdate(newValue)
      }
    }
  }
};
</script>

<style scoped>
.row {
  margin: 0;
}
.not-found {
  margin: 5rem auto;
}
</style>